<template>
  <v-app id="inspire" style="background-color: #fff; padding-left:1em; padding-right:1em;">

    <v-container>
      <div class="s--app-bar">
        <div class="s--app-bar-left">
          <div class="s-app-bar-logo" >
            <a href="" style="background:#306EE2; display:block; margin-top:-15px; margin-bottom:-12px; height:75px; padding:0 15px; display:flex; align-item:center">
              <img src="@/assets/logo-firpo.svg" width="50" alt="">
            </a>
          </div>
          <div style="color:#2a2c40;">
            <div>МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</div>
            <div>Институт развития профессионального образования</div>
          </div>
        </div>
        <div class="s--app-bar-right">
          <div v-if="is_loggined && userdata.last_name" style="margin-right:8px;">
            <v-icon size="32">mdi-badge-account-outline</v-icon>
          </div>
          <div v-if="is_loggined && userdata.last_name" style="font-size:14px; line-height:1.3em">
            <div>{{ userdata.last_name }} {{ userdata.first_name }} {{ userdata.patronymic }}</div>
            <div>{{ userdata.email }}</div>
          </div>
        </div>
      </div>
      <div class="s--app-bar__intro">
        Телефон технической поддержки: <a href="tel:84990090551">8 (499) 009-05-51</a> (доб. 5362)
      </div>
      <v-row>
        <v-col :lg="is_loggined ? 3 : 4" :cols="12">
          <div class="mt-3">
            <v-expansion-panels class="mt-3" elevation="0">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h4>Нормативно-правовые документы. Инструкция.</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list >
                    <v-list-item href="/docs/i.pdf" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-2">mdi-information-outline</v-icon> Инструкция</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="/docs/sostav.pdf" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-2">mdi-account-group-outline</v-icon> Состав экспертной группы</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="/docs/poloz.pdf" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-2">mdi-file-document-alert</v-icon> Положение экспертной группы</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="/docs/reglament.pdf" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-2">mdi-file-document-multiple-outline</v-icon> Регламент экспертной группы</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item href="/docs/mtd.pdf" target="_blank">
                      <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-2">mdi-file-document-alert-outline</v-icon> Методика расчета новых критериев</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col :lg="is_loggined ? 7 : 8" :cols="is_loggined ? 12 : 12">
          <v-expansion-panels class="mt-3" elevation="0">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>Заявки участников в конкурс на предоставление в 2026 году грантов в форме субсидий</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                из федерального бюджета на создание кластеров среднего профессионального образования в рамках федерального проекта «Профессионалитет» государственной программы Российской Федерации «Развитие образования»
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col :lg="is_loggined ? 2 : 0" :cols="is_loggined ? 12 : 0" v-if="is_loggined">
          <div class="mt-3">
            <v-btn block x-large elevation="0" @click="logout()">
              <v-icon>mdi-logout</v-icon>
              Выйти
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-main> 
      <div class="container-content pt-3 pb-3">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export default {
  name: "App",
  data: () => ({ 
    is_loggined: false,
    userdata: {}
  }),
  watch: {
    '$route': {
        handler: function() {
          this.is_loggined = (this.$auth.getToken() ? true : false)
          try {
            if (this.$auth.getToken()) {
              this.userdata = parseJwt(this.$auth.getToken())
            }
          } catch (_error) {console.error(_error)}
        },
        deep: true,
        immediate: true
    }
  },
  methods: {
    logout(){
      this.$auth.destroyToken()
      this.$router.push({"name":"LoginPage"})
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;600&display=swap");
#app {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7e7786 !important;
}

.app-special-drawler {
  position: relative;
  border-bottom: 1px solid #e6e6e9 !important;
}
.drawler-absolute {
  position: absolute;
  left: 10px;
  top: 0;
  height: inherit;
  display: flex;
  align-items: center;
}
.container-content {
  max-width: 1440px;
  margin: 0 auto;
}
</style>
